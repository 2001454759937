<template>
    <div data-app>
      <template>
        <v-data-table
            :headers="headers"
            :items="listTeachers"
            class="elevation-1"
            :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-card-title class="text-h5 pa-0">
                Danh sách giáo viên
                <v-spacer></v-spacer>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-btn width="100" color="primary" dark class="mb-2" to="/add-teacher" target="_blank"
              >Thêm mới
              </v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.img="{ item }">
            <v-img :src="item.img" max-height="100" max-width="100"></v-img>
          </template>
          <template v-slot:item.status="{ item }">
            <v-switch v-model="item.status" :disabled="true"></v-switch>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <!--        <template v-slot:no-results>-->
          <!--          <v-alert :value="true" color="error" icon="warning">-->
          <!--            Your search for "{{ search }}" found no results.-->
          <!--          </v-alert>-->
          <!--        </template>-->
        </v-data-table>
        <!-- DIALOG DELETE  -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </div>
  </template>
  
  <script>
  import {mapGetters} from "vuex";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import { SET_LOADING } from "@/store/loading.module";
  import { GET_LIST_TEACHER,DELETE_TEACHER } from "@/store/teacher.module";
  import { ERROR } from "@/store/alert.module";
  
  export default {
    data: () => ({
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Tên giáo viên", value: "name" },
        { text: "Image", value: "img" },
        { text: "Giới thiệu", value: "content" },
        { text: "Môn dạy", value: "subject" },
        { text: "Học vị", value: "degree" },
        { text: "Số năm kinh nghiệm", value: "experience" },
        { text: "Phụ trách lớp", value: "class" },
        { text: "Nơi công tác", value: "address" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedId: -1,
      dialogDelete: false,
      domain: process.env.VUE_APP_BASE_URL + "/",
    }),
  
    computed: {
      ...mapGetters({
        listTeachers: "listTeachers",
      }),
    },

    mounted() {
      this.getSubjects();
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Giáo viên", route: "teacher" },
      ]);
    },
    methods: {
      getSubjects() {
        this.$store.dispatch(SET_LOADING, true);
        this.$store
            .dispatch(GET_LIST_TEACHER)
            .then(() => {
              this.$store.dispatch(SET_LOADING, false);
            })
            .catch(() => {
              this.$store.dispatch(SET_LOADING, false);
            });
      },
  
      editItem(item) {
        this.$router.push({ name: "detail-teacher", query: { id: item.id } });
      },
  
      deleteItem(item) {
        console.log(item)
        this.editedIndex = this.listTeachers.indexOf(item);
        this.editedId = item.id;
        this.dialogDelete = true;
      },
      deleteItemConfirm() {
        this.$store.dispatch(DELETE_TEACHER, this.editedId).then((data) => {
          if (data.status) {
            this.$store.dispatch(GET_LIST_TEACHER)
          } else {
            if(data.errorCode == 424){
              this.$store.dispatch(ERROR, data.message, {root: true});
            } else{
              this.$store.dispatch(ERROR, "Xóa thất bại!", {
                root: true,
              });
            }
          }
        });
  
        this.closeDelete();
      },
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedIndex = -1;
        });
      },
    },
  };
  </script>
  <style>
  #my-strictly-unique-vue-upload-multiple-image {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  
  h1,
  h2 {
    font-weight: normal;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    display: inline-block;
    margin: 0 10px;
  }
  
  a {
    color: #42b983;
  }
  </style>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  